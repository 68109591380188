<template>
    <div class="container">
        <infusion-center-info />
        <infusion-time-select />
    </div>
</template>

<script>
import InfusionTimeSelect from '@/components/infusion-time-select/index.vue'
import InfusionCenterInfo from '@/components/infusion-center-info/index.vue'
export default {
    // 预约页面
    name: 'Preengage',
    components: {
        InfusionTimeSelect,
        InfusionCenterInfo
    }
}
</script>

<style lang="scss" scoped>
.container {
    // background-color: #fff;
    // padding: 11px 12px 17px 13px;
    // box-sizing: border-box;
}
</style>