<template>
  <div class="preengage-detail-container">
    <status-info
      :title="mapTitle(applyInfo.infusionStatus)['str']"
      :info="mapTitle(applyInfo.infusionStatus)['info']"
      :time="`${
        applyInfo.infusionDate && applyInfo.infusionDate.split(' ')[0]
      } ${applyInfo.infusionWeek} ${applyInfo.infusionTime}`"
    />
    <infusion-center-info
      :centerInfo="centerInfo"
      @navigate="handleNavigate"
      :applyInfo="applyInfo"
    />
    <card-info-kv
      :imgPath="baseImgPath"
      title="患者信息"
      :infoArray="[
        {
          key: '姓名：',
          value: desensitizeName(applyInfo.patientName),
        },
        {
          key: '联系方式：',
          value: desensitizePhone(applyInfo.patientPhone),
        },
        {
          key: '年龄：',
          value: applyInfo.patientAge,
        },
      ]"
    />

    <div class="goods_wrapper">
      <p class="title">
        <img
          class="drug_img"
          src="~@img/cardInfo/药品信息.svg"
          alt=""
        />药品信息
      </p>
      <!-- <van-cell-group> -->
      <div>
        <SelectedDrugItem
          v-for="item in goodDTOs"
          :item="item"
          :swipeDisabled="true"
        />
      </div>
      <!-- </van-cell-group> -->
    </div>

    <card-info-kv
      :imgPath="otherImgPath"
      title="其他信息"
      :infoArray="[
        {
          key: '疾病诊断：',
          value: applyInfo.diagnoseName,
        },
        {
          key: '给药方式：',
          value: Number(applyInfo.infusionType) === 1 ? '静脉输注' : '皮下注射',
        },
        {
          key: '特殊备注：',
          value: applyInfo.remark,
        },
      ]"
    />

    <!-- 上传图片 -->
    <Uploader
      v-if="showUploader"
      :photoList="photoShowList"
      :needEdit="false"
      :needSupplementary="Number(applyInfo.infusionStatus) === 1"
      @onDelPhoto="onDelPhoto"
      @onSupplementary="onSupplementary"
    />

    <!-- 需要补充信息的时候需要自己在自己的组件加上这块-->
    <van-popup
      v-model="isShowPopup"
      :style="{ height: '30%' }"
      position="bottom"
      :closeable="false"
    >
      <Uploader
        :needEdit="true"
        :needSupplementary="false"
        :photoList="photoList"
        @onChooseImg="onChooseImg"
        @onDelPhoto="onDelPhoto"
      />
      <BottomBtns
        :btns="[
          {
            text: '取消',
            type: 'plain',
            eventName: 'onReject',
          },
          {
            text: '上传',
            type: 'primary',
            eventName: 'onSave',
          },
        ]"
        @onSave="onSubmitUpload"
        @onReject="onCancelUpload"
      />
    </van-popup>
    <!-- 预约成功、已取消、预约失败，且输注中心未被删除，显示【再次预约】 -->
    <!-- 底部操作按钮 -->
    <bottom-btns
      v-if="[2, 3, 4].includes(Number(applyInfo.infusionStatus))"
      :btns="[
        {
          text: '再次预约',
          type: 'primary',
          eventName: 'applyAgain',
        },
      ]"
      @applyAgain="handleApplyAgain"
    />

    <!-- 状态是待审核、预约完成，且时间未到预约时间的情况，可点击【取消预约】 -->
    <!-- 1=待审核；2=预约成功；3=预约失败；4=取消预约 -->
    <bottom-btns
      v-if="
        ([2].includes(Number(applyInfo.infusionStatus)) && !isAfter) ||
        Number(applyInfo.infusionStatus) === 1
      "
      :btns="[
        {
          text: '取消预约',
          type: 'primary',
          eventName: 'cancelApply',
        },
      ]"
      @cancelApply="handleCancelApply"
    />
  </div>
</template>

<script>
import StatusInfo from '@/components/status-info/index.vue'
import InfusionCenterInfo from '@/components/infusion-center-info/index.vue'
import CardInfoKv from '@/components/card-info-kv/index.vue'
import BottomBtns from '@/components/bottom-btns/index.vue';
import api from '@/api'
import WxTool from '@/utils/wx-tool';
import moment from 'moment';
import { Toast } from 'vant'
import Uploader from '@/components/uploader';
import { desensitizePhone, desensitizeName, getFileSize, base64ToFile, computeAfterDay } from '@/utils';
import SelectedDrugItem from '@/components/selectedDrugItem/index.vue'

export default {
  name: 'PreengageDetailContainer',
  components: {
    StatusInfo,
    InfusionCenterInfo,
    CardInfoKv,
    BottomBtns,
    Uploader,
    SelectedDrugItem
  },
  data () {
    return {
      scopeImgPath: require('../../assets/images/cardInfo/输注范围.svg'),
      feeImgPath: require('../../assets/images/cardInfo/输注价格.svg'),
      auxiliaryImgPath: require('../../assets/images/cardInfo/辅助药品.svg'),
      processImgPath: require('../../assets/images/cardInfo/预约流程.svg'),
      materialImgPath: require('../../assets/images/cardInfo/输注材料.svg'),
      briefImgPath: require('../../assets/images/cardInfo/中心简介.svg'),
      baseImgPath: require('../../assets/images/cardInfo/基本信息.svg'),
      otherImgPath: require('../../assets/images/cardInfo/其他信息.svg'),
      goodDTOs: [],
      showUploader: false,
      desensitizePhone,
      desensitizeName,
      centerInfo: {},
      applyInfo: {},
      queryObj: {},
      isAfter: false,
      failReason: '',
      photoList: [],
      photoShowList: [],
      localIds: [], // localIds转换成serverId 给后端
      LocalImgData: [],
      isShowPopup: false,
      tipData: []
    }
  },
  created () {
    this.queryObj = this.getQueryStringArgs()
    this.getApplyDetail()
  },
  methods: {
    // 导航
    handleNavigate () {
      const instance = WxTool.getInstance();
      // 定位
      instance.navigate(this.centerInfo.latitude, this.centerInfo.longitude)
    },

    mapTitle (status) {
      console.log('mapTitle', status)
      // 1=待审核；2=预约成功；3=预约失败；4=取消预约
      let str;
      let info;
      switch (Number(status)) {
        case 1:
          str = '待审核'
          info = '等待输注中心审核'
          break;
        case 2:
          str = '预约成功'
          info = '预约成功，请按时前往输注中心进行输注'
          break;
        case 3:
          str = '预约失败'
          info = '具体的失败原因：' + this.failReason
          break;
        case 4:
          str = '已取消'
          info = '您已取消预约，如需继续前往可点击“再次预约”重新发起预约。'
          break;
        default:
          break;
      }

      return {
        str,
        info,
      }
    },
    // 获取url query参数方法
    getQueryStringArgs () {
      const qs = location.search.length > 0 ? location.search.substring(1) : ''
      const args = {};
      const arr = qs.split('&').map((kv) => kv.split('='));
      arr.forEach((item) => {
        args[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
      });
      return args;
    },

    computeIsAfter (date, time) {
      const givenDate = date.split(' ')[0]
      // const givenHour = time.split('~')[0];
      const givenHour = '00:00'

      // 例如：预约时间2024-05-06 ： 12:00 ~ 13:00 , 则givenMoment = 2024-05-06 00:00
      const givenMoment = moment(givenDate + ' ' + givenHour, 'YYYY-MM-DD HH:mm')

      const now = moment();
      const isAfter = now.isAfter(givenMoment);

      // isAfter ? '当前时间超过给定时间' : '没超过'
      console.log(isAfter ? 'isAfter当前时间超过给定时间' : 'isAfter没超过');
      return isAfter
    },

    mapRejectType (status) {
      if (Number(status) === 1) {
        return '患者资料不全';
      }
      if (Number(status) === 2) {
        return '预约时间冲突';
      }
      if (Number(status) === 3) {
        return '输注中心暂无法输注此药品';
      }
      if (Number(status) === 4) {
        return '其他';
      }
    },

    mapCancelType (status) {
      // 1=计划有变，不想去了；2=预约时间调整，想重新预约；3=输注中心通知无法接待；4=其他
      if (Number(status) === 1) {
        return '计划有变，不想去了';
      }
      if (Number(status) === 2) {
        return '预约时间调整，想重新预约';
      }
      if (Number(status) === 3) {
        return '输注中心通知无法接待';
      }
      if (Number(status) === 4) {
        return '其他';
      }
    },

    deepCopyArray (arr) {
      if (!Array.isArray(arr) || arr.length === 0) return [];
      return JSON.parse(JSON.stringify(arr));
    },

    async getApplyDetail () {
      const res = await api.applyDetail({
        id: this.queryObj.id
      })

      if (res.code === '0') {
        this.centerInfo = res.data.infusionCenterDTO
        this.applyInfo = res.data

        const { infusionStatus, rejecType, rejectComment, cancelType, cancelComment, infusionDate, infusionTime } = res.data

        this.isAfter = this.computeIsAfter(infusionDate, infusionTime)
        // 状态： 1=待审核；2=预约成功；3=预约失败；4=取消预约

        this.failReason = Number(infusionStatus) === 3
          ? Number(rejecType) === 4
            ? rejectComment
            : this.mapRejectType(rejecType)
          : Number(cancelType) === 4
            ? cancelComment
            : this.mapCancelType(cancelType)
        // 预约日在今天之前的，不展示资料模块
        this.showUploader = !computeAfterDay(infusionDate.split(' ')[0])
        this.photoList = res.data.infusionImages || [];
        this.photoShowList = this.deepCopyArray(res.data.infusionImages) || [];
        this.goodDTOs = res.data.goodDTOs || []
      }
    },

    // 再次预约
    handleApplyAgain () {
      this.$router.push({
        path: '/appointmentApply',
        query: {
          id: this.applyInfo.infusionCenterCode,
          again: 1,
          applyId: this.queryObj.id,
        }
      })
    },

    // 取消预约
    handleCancelApply () {
      const { patientName,
        patientPhone,
        infusionDate,
        infusionWeek,
        infusionTime,
        createdTime,
        infusionCenterCode,
        infusionCenterDTO: { infusionCenterName },
        id } = this.applyInfo
      this.$router.push({
        path: '/cancelApply',
        query: {
          infusionCenterName,
          patientName,
          patientPhone,
          infusionDate,
          infusionWeek,
          infusionTime,
          createdTime,
          id,
          infusionCenterCode,
        }
      })
    },

    onSupplementary () {
      this.isShowPopup = true;
    },

    onCancelUpload () {
      this.isShowPopup = false;
    },

    async onSubmitUpload () {
      if (!this.photoList || (this.photoList && this.photoList.length === 0)) {
        return Toast.fail('请上传资料')
      }
      const param = {
        id: this.queryObj.id,
        mediaIds: this.photoList
      }
      const res = await api.saveImages(param)
      if (res.code === '0') {
        this.isShowPopup = false;
        this.getApplyDetail();
      }
    },

    onDelPhoto (index) {
      this.photoList && this.photoList.splice(index, 1);
    },

    async onChooseImg (chooseLocalIds) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      this.localIds = [...chooseLocalIds]; // 记录原数组
      await this.getLocalImgData([...chooseLocalIds]);
    },

    async getLocalImgData (localIds) {
      const instance = WxTool.getInstance();
      const localId = localIds[0];
      const shiftedIndex = this.localIds && this.localIds.indexOf(localId);
      localIds.shift();
      instance.getLocalImgData(localId.toString(), async (res) => {
        // 计算图片大小
        if (res.indexOf('data:image') != 0) { // 安卓机没有前缀返回 兼容
          //判断是否有这样的头部
          res = 'data:image/jpeg;base64,' + res
        }
        res = res.replace(/\r|\n/g, '').replace('data:image/jpg', 'data:image/jpeg')
        const fileSize = getFileSize(res); // 获取图片的大小，单位为KB
        if (fileSize > 5) {
          console.log(fileSize, shiftedIndex, '683')
          this.tipData.push(shiftedIndex + 1);
        } else {
          this.LocalImgData.push(res)
        }
        // 图片大小符合要求，继续处理
        if (localIds.length > 0) {
          await this.getLocalImgData(localIds);
        } else {
          console.log(this.LocalImgData, '结果');
          if (this.LocalImgData.length > 0) {
            this.uploadFlie(this.LocalImgData, this.tipData);
          } else { // 一张也没上传成功
            if (this.localIds.length === 1 && this.tipData.length === 1) {
              Toast.fail('图片太大了！');
            } else if (this.localIds.length > 1 && this.tipData.length > 0) {
              Toast.fail(`有${this.tipData.length}张图片太大了`);
            }
          }
          this.LocalImgData = [];
          this.tipData = [];
        }
      });
    },

    async uploadFlie (localData, tips) {
      console.log(localData, '691')
      const form = new FormData();
      for (let i = 0; i < localData.length; i++) {
        const file = base64ToFile(localData[i], '图片');
        form.append('files', file);
      }
      try {
        const res = await api.uploadFilePre(form);
        if (res && res.code === '0') {
          console.log(res.data, '697')
          res.data && res.data.map((item) => {
            this.photoList.push(item.fileUrl);
          });
        }
      } catch (err) {
      } finally {
        Toast.clear();
      }
      if (this.localIds.length === 1 && tips.length === 1) {
        Toast.fail('图片太大了！');
      } else if (this.localIds.length > 1 && tips.length > 0) {
        Toast.fail(`有${tips.length}张图片太大了`);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.preengage-detail-container {
  padding-bottom: 120px;
  .goods_wrapper {
    margin-bottom: 10px;
    .drug_img {
      height: 18px;
      vertical-align: text-bottom;
    }
    .title {
      background: #fff;
      color: #3a3a3a;
      font-weight: 500;
      font-size: 16px;
      height: 18px;
      line-height: 18px;
      padding: 15px 15px 0 15px;
    }
  }
}
</style>
