<template>
  <div class="container">
    <p class="title"><img :src="imgPath" />{{ title }}</p>
    <!-- 正常解析 -->
    <p v-if="!isHtml" class="info" v-for="(item, key) in infoArray">
      {{ item }}
    </p>

    <!-- 按html解析 -->
    <p v-if="isHtml" class="info" v-html="infoHtml"></p>
  </div>
</template>

<script>
export default {
  name: 'CardInfo',
  data () {
    return {

    }
  },
  props: {
    isHtml: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    infoHtml: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    title: {
      type: String,
      required: true,
      default () {
        return ''
      }
    },
    infoArray: {
      type: Array,
      required: false,
      default () {
        return []
      }
    },
    imgPath: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  margin-bottom: 8px;
  padding: 15px 18px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;

  .title {
    margin-bottom: 9px;
    height: 16px;
    font-size: 16px;
    font-weight: 500;
    color: #3a3a3a;
    line-height: 16px;
    img {
      height: 20px;
      vertical-align: text-bottom;
      margin-right: 4px;
      // margin-top: 1px;
    }
  }

  .info {
    margin-bottom: 4px;
    padding-left: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #3a3a3a;
    line-height: 20px;
  }
}
</style>
