<template>
  <div class="container">
    <div class="header">
      <div class="content">
        <img class="magnifier" src="./btn_serch_grey@2x.png" alt="" />
        <input
          type="text"
          class="input"
          v-model="inputValue"
          ref="inputRef"
          placeholder="请输入输注中心名称"
        />
      </div>
      <span class="search" @click="handleClickSearch">搜索</span>
    </div>

    <div class="center_list">
      <!-- 搜索结果 -->
      <infusion-center-item
        v-if="infusionCenterList.length"
        v-for="infusion in infusionCenterList"
        :key="infusion.id"
        :infusionObj="infusion"
        @apply="handleApply"
        @detail="handleDetail"
      />

      <div v-if="infusionCenterList.length === 0" class="empty_box">
        <img src="~@img/empty.png" alt="" class="empty_img" />
        <p class="empty_tip">没有更多了</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import InfusionCenterItem from '../../components/infusion-center-item/index.vue';
export default {
  name: 'search-center',
  components: {
    InfusionCenterItem,
  },
  data () {
    return {
      inputValue: '',
      infusionCenterList: [],
    };
  },
  mounted () {
    // 自动聚焦
    this.$refs.inputRef.focus()
  },
  methods: {

    // 点击预约申请了
    handleApply (obj) {
      console.log('handleApply', obj);
      this.$router.push({
        path: '/appointmentApply',
        query: {
          id: obj.infusionCenterCode,
        },
      });
    },

    // 中心详情
    handleDetail (obj) {
      this.$router.push({
        path: '/center-detail',
        query: {
          id: obj.infusionCenterCode,
        },
      });
    },

    async handleClickSearch () {
      const res = await api.getInfusionList({
        "infusionCenterName": this.inputValue && this.inputValue.trim(),
        "latitude": sessionStorage.getItem('latitude'),
        "longitude": sessionStorage.getItem('longitude'),
        pageNumber: 1,
        pageSize: 1000,
      });

      if (res.data && res.data.list) {
        this.infusionCenterList = res.data.list;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  .header {
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    height: 44px;
    padding: 8px 18px 8px 14px;
    background: #ffffff;
    box-shadow: 0px 0px 8px 0px rgba(60, 107, 240, 0.1);
    backdrop-filter: blur(10px);
    box-sizing: border-box;
    .content {
      position: relative;
      width: 301px;
      height: 28px;
      background: #f5f7fa;
      border-radius: 100px;
      .magnifier {
        position: absolute;
        top: 4px;
        left: 11px;
        width: 20px;
        height: 20px;
      }

      .input {
        position: absolute;
        top: 4px;
        left: 40px;
        width: 250px;
        height: 20px;
        border: none;

        background: #f5f7fa;

        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
        font-weight: 400px;
        line-height: 20px;
      }
    }
    .search {
      position: absolute;
      right: 18px;
      top: 12px;

      // width: 28px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #3c6bf0;
      line-height: 20px;
    }
  }

  .center_list {
    padding: 58px 14px;

    .empty_box {
      height: calc(100% - 88px);
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-top: 80px;
    }

    .empty_tip {
      font-size: 14px;
      color: #90949e;
      text-align: center;
    }
  }
}
</style>