var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"preengage-detail-container"},[_c('status-info',{attrs:{"title":_vm.mapTitle(_vm.applyInfo.infusionStatus)['str'],"info":_vm.mapTitle(_vm.applyInfo.infusionStatus)['info'],"time":((_vm.applyInfo.infusionDate && _vm.applyInfo.infusionDate.split(' ')[0]) + " " + (_vm.applyInfo.infusionWeek) + " " + (_vm.applyInfo.infusionTime))}}),_c('infusion-center-info',{attrs:{"centerInfo":_vm.centerInfo,"applyInfo":_vm.applyInfo},on:{"navigate":_vm.handleNavigate}}),_c('card-info-kv',{attrs:{"imgPath":_vm.baseImgPath,"title":"患者信息","infoArray":[
      {
        key: '姓名：',
        value: _vm.desensitizeName(_vm.applyInfo.patientName),
      },
      {
        key: '联系方式：',
        value: _vm.desensitizePhone(_vm.applyInfo.patientPhone),
      },
      {
        key: '年龄：',
        value: _vm.applyInfo.patientAge,
      } ]}}),_c('div',{staticClass:"goods_wrapper"},[_vm._m(0),_c('div',_vm._l((_vm.goodDTOs),function(item){return _c('SelectedDrugItem',{attrs:{"item":item,"swipeDisabled":true}})}),1)]),_c('card-info-kv',{attrs:{"imgPath":_vm.otherImgPath,"title":"其他信息","infoArray":[
      {
        key: '疾病诊断：',
        value: _vm.applyInfo.diagnoseName,
      },
      {
        key: '给药方式：',
        value: Number(_vm.applyInfo.infusionType) === 1 ? '静脉输注' : '皮下注射',
      },
      {
        key: '特殊备注：',
        value: _vm.applyInfo.remark,
      } ]}}),(_vm.showUploader)?_c('Uploader',{attrs:{"photoList":_vm.photoShowList,"needEdit":false,"needSupplementary":Number(_vm.applyInfo.infusionStatus) === 1},on:{"onDelPhoto":_vm.onDelPhoto,"onSupplementary":_vm.onSupplementary}}):_vm._e(),_c('van-popup',{style:({ height: '30%' }),attrs:{"position":"bottom","closeable":false},model:{value:(_vm.isShowPopup),callback:function ($$v) {_vm.isShowPopup=$$v},expression:"isShowPopup"}},[_c('Uploader',{attrs:{"needEdit":true,"needSupplementary":false,"photoList":_vm.photoList},on:{"onChooseImg":_vm.onChooseImg,"onDelPhoto":_vm.onDelPhoto}}),_c('BottomBtns',{attrs:{"btns":[
        {
          text: '取消',
          type: 'plain',
          eventName: 'onReject',
        },
        {
          text: '上传',
          type: 'primary',
          eventName: 'onSave',
        } ]},on:{"onSave":_vm.onSubmitUpload,"onReject":_vm.onCancelUpload}})],1),([2, 3, 4].includes(Number(_vm.applyInfo.infusionStatus)))?_c('bottom-btns',{attrs:{"btns":[
      {
        text: '再次预约',
        type: 'primary',
        eventName: 'applyAgain',
      } ]},on:{"applyAgain":_vm.handleApplyAgain}}):_vm._e(),(
      ([2].includes(Number(_vm.applyInfo.infusionStatus)) && !_vm.isAfter) ||
      Number(_vm.applyInfo.infusionStatus) === 1
    )?_c('bottom-btns',{attrs:{"btns":[
      {
        text: '取消预约',
        type: 'primary',
        eventName: 'cancelApply',
      } ]},on:{"cancelApply":_vm.handleCancelApply}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"title"},[_c('img',{staticClass:"drug_img",attrs:{"src":require("@img/cardInfo/药品信息.svg"),"alt":""}}),_vm._v("药品信息 ")])}]

export { render, staticRenderFns }