<template>
  <!-- 输注中心详情 -->
  <div class="center_detail_container">
    <!-- 中心展示轮播图 -->
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        v-for="(image, index) in centerInfo.infusionImage"
        :key="index"
      >
        <img v-lazy="image" />
      </van-swipe-item>
    </van-swipe>

    <!-- 中心信息 -->
    <infusion-center-info @navigate="handleNavigate" :centerInfo="centerInfo" />

    <!-- tab -->
    <div class="tab_wrapper">
      <div class="tab">
        <div
          class="item"
          :class="{ selected: tabIndex === 1 }"
          @click="handleClickTab(1)"
        >
          输注范围
        </div>
        <div
          class="item"
          :class="{ selected: tabIndex === 2 }"
          @click="handleClickTab(2)"
        >
          注意事项
        </div>
        <div
          class="item"
          :class="{ selected: tabIndex === 3 }"
          @click="handleClickTab(3)"
        >
          中心简介
        </div>
      </div>
    </div>

    <card-info
      id="scope"
      class="scope"
      title="输注范围"
      :isHtml="true"
      :infoHtml="infusionScopeList"
      :imgPath="scopeImgPath"
    />

    <card-info
      title="输注收费"
      :infoArray="infusionFee"
      :imgPath="feeImgPath"
    />

    <card-info
      title="可提供辅助药品"
      :infoArray="auxiliaryMedicine"
      :imgPath="auxiliaryImgPath"
    />

    <card-info
      id="notice"
      title="预约与输注流程"
      :isHtml="true"
      :infoHtml="infoHtml"
      :imgPath="processImgPath"
    />

    <!-- :infoArray="['① 病历', '② 处方', '③ 血检']" -->
    <card-info
      title="输注需提供材料"
      :infoArray="materialList"
      :imgPath="materialImgPath"
    />

    <card-info
      id="brief"
      title="中心简介"
      :infoArray="[centerInfo.infusionIntroduce]"
      :imgPath="briefImgPath"
    />

    <bottom-btns
      :btns="[
        {
          text: '立即预约',
          type: 'primary',
          eventName: 'yuyue',
        },
      ]"
      @yuyue="handleClickPreengage"
    />
  </div>
</template>

<script>
import InfusionCenterInfo from '@/components/infusion-center-info/index.vue';
import CardInfo from '@/components/card-info/index.vue';
import BottomBtns from '@/components/bottom-btns/index.vue';
import WxTool from '@/utils/wx-tool';
import api from '@/api';
import { Toast } from 'vant'

export default {
  // 预约页面
  name: 'CenterDetail',
  components: {
    // InfusionTimeSelect,
    InfusionCenterInfo,
    CardInfo,
    BottomBtns
  },
  data () {
    return {
      scopeImgPath: require('../../assets/images/cardInfo/输注范围.svg'),
      feeImgPath: require('../../assets/images/cardInfo/输注价格.svg'),
      auxiliaryImgPath: require('../../assets/images/cardInfo/辅助药品.svg'),
      processImgPath: require('../../assets/images/cardInfo/预约流程.svg'),
      materialImgPath: require('../../assets/images/cardInfo/输注材料.svg'),
      briefImgPath: require('../../assets/images/cardInfo/中心简介.svg'),

      infusionFee: [],
      infoHtml: `<p class="content process">
        <span class="bold">预约申请</span>
        <span class="plain">患者填写并选择预约时间</span>
      </p>
      <p class="content process">
        <span class="bold">预约确认</span>
        <span class="plain"
          >输注中心确认预约输注时间，如遇时间冲突，输注中心将与患者联系更改</span
        >
      </p>
      <p class="content process">
        <span class="bold">药品配送</span>
        <span class="plain">思派药房按输注中心确认时间配送至输注中心</span>
      </p>
      <p class="content process">
        <span class="bold">按时到店</span>
        <span class="plain">患者按预约时间携带必备资料至输注中心</span>
      </p>
      <p class="content process">
        <span class="bold">核对输注</span>
        <span class="plain"
          >输注中心检查药品完整性，核对患者预约信息、病历及处方资料无误后进行输注</span
        >
      </p>`,
      tabIndex: 1,
      images: [
        'https://img01.yzcdn.cn/vant/apple-1.jpg',
        'https://img01.yzcdn.cn/vant/apple-2.jpg',
      ],
      centerInfo: {},
      infusionScopeList: [],
      auxiliaryMedicine: [],
      materialList: []
    }
  },
  created () {
    const centerCode = this.$route.query.id
    console.log('centerCode', centerCode)
    this.getDetail(centerCode)
  },
  mounted () {

  },
  methods: {

    async getDetail (infusionCenterCode) {
      const res = await api.getInfusionDetail({
        infusionCenterCode
      })

      this.centerInfo = res.data

      const {
        infusionSubcutaneou, infusionVein, isAuxiliaryMedicine, auxiliaryMedicineRemark, infusionMaterial, infusionMaterialRemark
      } = res.data

      if (this.centerInfo.infusionRange) {
        // 去掉多余空行
        let newStr = this.centerInfo.infusionRange.replace(/\n+/g, '\n');
        this.infusionScopeList = newStr.split('\n')
        let str = ''
        this.infusionScopeList.forEach(item => {
          const arr = item.split('：')
          const title = arr[0]
          const content = arr[1] || ''
          str += `<p class="content"><span class="bold">${title}${content ? '：' : ''}</span></p><p style="padding-left: 16px; margin-bottom: 12px">${content}</p>`
        })
        this.infusionScopeList = str
      }

      this.infusionFee = [
        `皮下注射：${infusionSubcutaneou}`,
        `静脉输注：${infusionVein}`,
      ]

      this.auxiliaryMedicine = Number(isAuxiliaryMedicine) === 0 ? ['无'] : [auxiliaryMedicineRemark]

      this.materialList = infusionMaterial.split(',').map(material => {
        return this.mapMaterial(material)
      })

      if (infusionMaterial.split(',').includes('6')) {
        this.materialList.push(`⑥${infusionMaterialRemark}`)
      }
    },

    mapMaterial (id) {
      const map = {
        1: '①出院小结/病历',
        2: '②医生处方',
        3: '③购药凭证',
        4: '④近期肝肾功能血常规检查单',
        5: '⑤近期血检报告',
      }
      return map[id]
    },

    handleNavigate () {
      const instance = WxTool.getInstance();
      console.log('instance', instance);

      // 定位
      instance.navigate(this.centerInfo.latitude, this.centerInfo.longitude)
    },
    handleClickTab (tabIndex) {
      this.tabIndex = tabIndex

      const map = {
        1: 'scope',
        2: 'notice',
        3: 'brief'
      }

      var anchorElement = document.getElementById(map[String(tabIndex)]);

      // 使用平滑滚动效果定位到元素（需要兼容性考虑）
      anchorElement.scrollIntoView({ behavior: 'smooth' });
    },
    handleClickPreengage () {
      this.$router.push({
        path: '/appointmentApply',
        query: {
          id: this.centerInfo.infusionCenterCode
        }
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.center_detail_container {
  padding-bottom: 120px;

  .tab_wrapper {
    width: 100%;
    // text-align: center;
    display: flex;
    justify-content: center;
    .tab {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 350px;
      height: 42px;
      background: #ffffff;
      border-radius: 6px;
      box-sizing: border-box;
      border: 1px solid #f36218;
      overflow: hidden;
      .item {
        flex-grow: 1;
        // box-sizing: border-box;
        height: 100%;
        font-size: 14px;
        font-weight: 500;
        color: #333333;
        line-height: 42px;

        border-right: 1px solid #f36218;

        &:nth-child(3) {
          border-right: none;
        }

        &.selected {
          background: #f36218;
          color: #fff;
        }
      }
    }
    // height
  }

  .scope {
    margin-top: 8px;
  }

  /deep/ .container .info .content {
    color: #3a3a3a;
    font-size: 14px;
    line-height: 22px;
    font-weight: 100px;
    .bold {
      font-weight: 500;
      color: #333;
    }
  }

  /deep/ .container .info .process {
    display: flex;
    .bold {
      width: 70px;
    }
    .plain {
      flex: 1;
    }
  }
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: 200px;
  line-height: 150px;
  text-align: center;
  img {
    height: 200px;
    width: 100%;
  }
}
</style>