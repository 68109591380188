<template>
  <div class="card_info_kv_container">
    <p class="title"><img :src="imgPath" />{{ title }}</p>
    <!-- <p class="info" v-for="(item, key) in infoArray">{{ item }}</p> -->
    <p class="kv" v-for="(item, key) in infoArray">
      <span class="key">{{ item.key }}</span>
      <span @click="clickHandle" class="value" v-html="item.value"></span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'CardInfoKv',
  data () {
    return {

    }
  },
  methods: {
    clickHandle (target) {
      console.log('clickHandle', target);
      this.$emit('emitPhone')
    }
  },
  props: {

    imgPath: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },

    title: {
      type: String,
      required: true,
      default () {
        return '患者信息'
      }
    },
    infoArray: {
      type: Array,
      required: true,
      default () {
        return [
          {
            key: '姓名：',
            value: '张三'
          },
          {
            key: '疾病诊断：',
            value: '药品名（通用名）生产厂家 规格药品名（通用名）生产厂家 规格'
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card_info_kv_container {
  margin-bottom: 8px;
  padding: 15px 18px;
  box-sizing: border-box;
  width: 100%;
  background: #fff;

  .title {
    margin-bottom: 9px;
    height: 18px;
    font-size: 16px;
    font-weight: 500;
    color: #3a3a3a;
    line-height: 18px;
    img {
      height: 18px;
      vertical-align: text-bottom;
      margin-right: 4px;
    }
  }

  .kv {
    display: flex;
    margin-bottom: 4px;
    // height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #7b7b7b;
    line-height: 20px;

    .key {
      // display: inline-block;
      display: flex;
      vertical-align: top;
      width: 100px;
      height: 20px;
      font-size: 14px;
      font-weight: 400;
      color: #7b7b7b;
      line-height: 20px;
    }

    .value {
      flex: 1;
      vertical-align: top;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      word-break: break-all;
    }
  }
}
</style>
