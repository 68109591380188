<template>
  <div class="container">
    <span class="name" @click="handleClickName">{{
      centerInfo.infusionCenterName
    }}</span>

    <p class="tag_line">
      <button class="gongli tag" v-if="Number(centerInfo.infusionNatrue) === 0">
        公立
      </button>
      <button class="gongli tag" v-if="Number(centerInfo.infusionNatrue) === 1">
        民营
      </button>

      <button class="yibao tag" v-if="Number(centerInfo.medicareType) === 1">
        医保
      </button>
      <button class="yibao tag" v-if="Number(centerInfo.medicareType) === 0">
        自费
      </button>
    </p>

    <!-- 营业时间 -->
    <p class="time" v-html="timeList"></p>

    <!-- <p class="time" v-else>
      {{
        `${applyInfo.infusionDate.split(' ')[0]} ${applyInfo.infusionWeek} ${
          applyInfo.infusionTime
        }`
      }}
    </p> -->

    <!-- 地址 -->
    <p class="address" @click="navigate">
      <span class="text">{{
        `${centerInfo.provinceName}${centerInfo.cityName}${
          centerInfo.districtName
        }${centerInfo.infusionAddress}${centerInfo.infusionAddressRemark || ''}`
      }}</span>
      <button class="tel" @click.stop="handleMakePhone">
        <img class="icon" src="./tel@2x.png" alt="" />
        <a class="text">电话</a>
      </button>
    </p>

    <!-- <button class="navigation" @click="navigate">
      <img class="icon" src="./navigation@2x.png" alt="" />
      <span class="text">导航</span>
    </button> -->
  </div>
</template>

<script>
export default {
  name: 'InfusionCenterInfo',
  data () {
    return {
      telLink: '',
      timeList: ''
    };
  },
  props: {
    // showApplyTime: {
    //   type: Boolean,
    //   required: false,
    //   default () {
    //     return false
    //   }
    // },
    applyInfo: {
      type: Object,
      required: false,
      default () {
        return {}
      }
    },
    centerInfo: {
      type: Object,
      required: true,
      default () {
        return {}
      }
    }
  },
  methods: {
    handleClickName () {
      this.$router.push({
        path: '/center-detail',
        query: {
          id: this.centerInfo.infusionCenterCode
        }
      })
    },
    navigate () {
      this.$emit('navigate')
    },

    // 打电话
    handleMakePhone () {
      window.location.href = `tel:${this.centerInfo.infusionPhone}`
    }
  },
  watch: {
    'centerInfo': {
      handler (newV) {
        console.log('centerInfo enw', newV);
        this.telLink = `tel: ${newV.infusionPhone}`
        let str = ''
        if (newV &&
          newV.infusionBusinessHoursView) {
          newV.infusionBusinessHoursView.forEach(item => {
            console.log('item', item);
            str += `<p>${item}</p>\n`
          })
        }
        this.timeList = str
      },
      immediate: true
    },

    //  &&
    //   `<span>${centerInfo.infusionBusinessHoursView.join(' ')}</span>\n`
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  width: 100%;
  padding: 20px 16px 10px 16px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: #fff;

  .name {
    font-size: 20px;
    font-weight: 500;
    color: #3a3a3a;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tag_line {
    margin-top: 4px;
    .tag {
      width: 34px;
      height: 18px;
      margin-right: 8px;
      padding: 0;
      background: #ffffff;
      border-radius: 2px;
      border: 1px solid #fe6814;
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      color: #fe7125;
      line-height: 17px;
    }
  }
  .time {
    margin-top: 8px;
    padding-bottom: 13px;
    font-size: 14px;
    font-weight: 400;
    color: #3a3a3a;
    line-height: 18px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .address {
    display: flex;
    vertical-align: top;
    margin-top: 16px;
    font-size: 12px;
    font-weight: 400;
    color: #3a3a3a;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    &::before {
      content: '';
      display: inline-block;
      vertical-align: top;
      width: 16px;
      height: 16px;
      background-image: url('./location@2x.png');
      background-size: 16px 16px;
      margin-right: 6px;
    }
    .text {
      flex: 1;
    }
    .tel {
      position: relative;
      margin-left: 10px;
      margin-right: 10px;
      width: 34px;
      height: 34px;
      border-radius: 4px;
      background-color: #fff;
      border: 1px solid #f36218;
      .icon {
        position: absolute;
        top: 6px;
        left: 11px;
        width: 12px;
        height: 12px;
      }
      .text {
        position: absolute;
        bottom: -2px;
        left: 9px;
        height: 18px;
        font-size: 8px;
        font-weight: 500;
        color: #f36218;
        line-height: 18px;
      }
    }
  }

  // .navigation {
  //   position: absolute;
  //   right: 15px;
  //   bottom: 9px;
  //   width: 34px;
  //   height: 34px;
  //   border-radius: 4px;
  //   background-color: #fff;
  //   border: 1px solid #f36218;

  //   .icon {
  //     position: absolute;
  //     top: 6px;
  //     left: 11px;
  //     width: 12px;
  //     height: 12px;
  //   }

  //   .text {
  //     position: absolute;
  //     bottom: -2px;
  //     left: 9px;

  //     // width: 16px;
  //     height: 18px;
  //     font-size: 8px;
  //     font-weight: 500;
  //     color: #f36218;
  //     line-height: 18px;
  //   }
  // }
}
</style>
