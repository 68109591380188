<template>
  <div class="status-info-container">
    <p class="title">
      {{ title }}
    </p>
    <p class="info">
      {{ info }}
    </p>
    <p class="time" v-if="time">预约输注时间：{{ time }}</p>
  </div>
</template>

<script>
export default {
  // 预约详情的状态组件
  name: 'StatusInfo',
  props: {
    title: {
      type: String,
      required: true,
      default () {
        return '待审核';
      },
    },
    info: {
      type: String,
      required: true,
      default () {
        return '您已取消预约，如需继续前往可点击“再次预约”重新发起预约';
      },
    },
    time: {
      type: String,
      required: false,
      default () {
        return '';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.status-info-container {
  padding: 13px 18px;
  box-sizing: border-box;
  width: 100%;
  background-image: url('./bg@2x.png');
  background-size: 100% 100%;

  .title {
    margin-bottom: 2px;
    height: 33px;
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    line-height: 33px;
  }

  .info,
  .time {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 20px;
  }
}
</style>
